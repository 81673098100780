import { withStyles } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LocaleContext } from 'App'
import React, { useContext } from 'react'
import AppStore from '../../assets/app-store-02.png'
import GooglePlay from '../../assets/google-play-02.png'
import AppleApp from 'assets/apple-app-icon.png'
import Logo from '../../assets/logo-passenger.svg'
import LogoHindi from '../../assets/logo-passenger-hindi.svg'
import Logoatech from '../../assets/arohatech-logo.png'
import Niclogo from '../../assets/NIC-LOGO.png'
import Instagram from 'assets/instagram.png'
import KooApp from 'assets/koo.png'
import XLogo from 'assets/x-logo.png'

const styles = {
  facebook: {
    color: '#3b5998',
	 width: '32px',
    height: '32px',
    margin: '2px 5px 2px 0',
   
  },
  twitter: {
    color: '#00acee',
	 width: '32px',
    height: '32px',
    margin: '2px 5px 2px 0',
  },
  instagram: {
    width: '32px',
    height: '32px',
    color: '#833AB4',
    margin: '2px 5px 2px 0',
  },
}

const Footer = ({ classes }) => {
  const { t } = useTranslation('common')
   const lang = useContext(LocaleContext)
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <div className='logo'>
              <img src={lang === 'en' ? Logo : LogoHindi} alt='delhimetrorail' width="161" height="64"/>
            </div>
            <div className='get-in-touch d-none'>
              <h3>{t('footer.get-in-touch')}</h3>
              <p>
                {t('footer.main-footer-address-line1')}
                <br />
                {t('footer.main-footer-address-line2')}
                <br />
                {t('footer.main-footer-address-line3')}
              </p>
            </div>
          </div>
          <div className='col-md-9 col-lg-9'>
            <div className='get-in-touch'>
              <h3>{t('footer.quick-links')}</h3>
              <ul className='inline'>
                <li>
                  <Link to='/miscellaneous'>
                    {t('header-links.miscellaneous')}
                  </Link>
                </li>
				<li>
                  <Link to='/emergency-evacuation-plan'>
                  {t('footer.evacuation-plan')}
                  </Link>
		       </li>
			   <li>
                  <Link to='/faq'>{t('header-links.faq')}</Link>
                </li>
				<li>
                      <Link to='/security-dmrc'>
                        {t('header-links.security-dmrc')}
                      </Link>
                    </li>
					<li>
                  <Link to='/contact-us'>
                    {t('header-links.helpcontact')}
                  </Link>
                </li>
				<li>
                  <Link to='/disclaimer'>
                   {t('footer.disclaimer')}
                  </Link>
                </li>
				
				<li>
                <Link to='/privacy-policy' alt='Privacy Policy'>
                  	 {t('footer.privacy-policy')}
                </Link>
              </li>
			 </ul>
            </div>
            
            <hr />
            <div className='row'>
              <div className='col-md-5 col-lg-5 quick-contact'>
                <h3 className="d-none">{t('footer.quick-contact')}</h3>
				 <div className='row '>
                <div className="col-md-6">
                  {t('footer.quick-contact1')}
                  <strong>{t('footer.quick-contact1-num')} <b>{t('footer.quick-contact1-info')}</b></strong>
                </div>
                <div className="col-md-6">
                  {t('footer.quick-contact2')}
                  <strong>{t('footer.quick-contact2-num')}</strong>
                </div>
              </div>
              </div>
			  <div className='col-md-4 col-lg-4 download'>
              <h3 className="d-none">{t('footer.download-apps')}</h3>
              <ul className='app'>
                <li>
                  <a href='https://play.google.com/store/apps/details?id=com.sraoss.dmrc' target='_blank'>
                    <img src={GooglePlay} alt='Google Play' width="120" height="36"/>
                  </a>
                </li>
                <li>
                  <a href='https://apps.apple.com/in/app/delhi-metro-rail/id691105308' target='_blank'>
                    <img src={AppStore} alt='App Store' width="120" height="36"/>
                  </a>
                </li>
              </ul>
            </div>
              <div className='col-md-3 col-lg-3  social-links'>
                <h3 className="d-none">{t('footer.social-links')}</h3>
                <ul className="justify-content-end ">
				 <li>
                    <a
                      href='https://www.facebook.com/officialdmrc/'
                      target='_blank'
					  aria-label="Facebook"
                    >
                      <FacebookIcon className={classes.facebook} />
                      {/* <i className="flaticon-facebook-3"></i> */}
                    </a>
                  </li>
                  <li>
                    <a href='https://twitter.com/OfficialDMRC' target='_blank' aria-label="Twitter">
                      <img src={XLogo} alt="Twitter"  width="28" height="28" style={{marginTop: '5px', marginRight: '5px'}} />
                      {/* <i className="flaticon-twitter-sign"></i> */}
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.instagram.com/officialdmrc/?hl=en'
                      target='_blank' style={{lineHeight:'32px'}} aria-label="Instagram"
                    >
                      <img src={Instagram} alt="Instagram"  width="28" height="28"/>
                      {/*<InstagramIcon className={classes.instagram} />
                       <i className="flaticon-instagram"></i> */}
                    </a>
                  </li>
				  {/*<li>
                    <a
                      href='https://www.kooapp.com/profile/OfficialDMRC'
                      target='_blank' style={{lineHeight:'32px'}}
                    >
                      <img className="remove-white-bg" src={KooApp} alt="Instagram"  width="34" height="34"/>
                    </a>
                  </li>*/}
                </ul>
				</div>
				
				<div className='col-md-4  col-lg-5 social-links d-none'>
				<a href="/web-information-manager"><p>Web Information manager</p></a>
				<a href="https://www.arohatech.com/" target="_blank"><p><img src={Logoatech} alt="Arohatech - Web Portal Development Company and Mobile App Developer for Delhi Metro Rail Corporation (DMRC) India" title="Arohatech - Web Portal Development Company and Mobile App Developer for Delhi Metro Rail Corporation (DMRC) India" style={{width:'120px', marginBottom:5}}/> <br/ >Designed, Developed & Maintained by Arohatech IT Services</p></a>
				<a href="https://www.nic.in/" target="_blank"><p><img src={Niclogo} alt="National Informatics Center" style={{width:'90px', marginBottom:5}}/> <br/ > Hosted by National Informatics Center</p></a>
				</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default withStyles(styles)(Footer)
