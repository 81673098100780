import { LocaleContext } from 'App'
import NewImg from 'assets/images/corporateHome/new.gif'
import clsx from 'clsx'
import State from 'components/common/State'
import Footer from 'components/corporate/Footer'
import {
  API_BASE,
  fixTenderData,
  merryGoRound,
  reverseMerryGoRound,
  states,
  useFetch,
  API_ENDPOINT,
} from 'helpers/pageUtils'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import Pagination from '@material-ui/lab/Pagination';
import TenderImage from '../../assets/DMRC-ad-8x4-cms27122018.jpg'

const TenderTab = ({ currentTab, handleTabClick }) => {
  const { t } = useTranslation('passenger')
  const lang = useContext(LocaleContext)
  const { data, state } = useFetch(lang, 'tenderscategory', false, false)
  if (!data || state === states.LOADING) {
    return <State.Loading />
  }
  const handleClick = () => {
	document.getElementById('tender').style.display = "none";
  }
  const showModal = () => {
	document.getElementById('tender').style.display = "block";
  }
  return (
    <>
      <h1>{lang === 'en' ? 'Tenders' : 'निविदा'}</h1>
      <ul className='tenderslist mt-3 mb-5'>
        {state === states.SUCCESS &&
          Array.isArray(data?.items) &&
          data.items.map((item, i) => (
            <li
              key={parseInt(item?.meta?.order)}
              className={clsx({
                active: currentTab === item.id,
              })}
              onClick={() => {handleTabClick(item.id), showModal()}}
            >
              <div className='imageIcons'>
                <img
                  src={`${API_BASE}${item?.meta?.icon?.meta?.download_url}`}
                  alt={i}
                />
              </div>
              <p>{item?.meta[lang === 'en' ? 'english' : 'hindi']}</p>
            </li>
          ))}
      </ul>
      <h4 className='headingSmall'>
        {(state === states.SUCCESS &&
          Array.isArray(data?.items) &&
          data.items.filter((i) => i.id === currentTab)[0]?.meta[
            lang === 'en' ? 'english' : 'hindi'
          ]) ||
          ''}
      </h4>
	  {/*state === states.SUCCESS && (currentTab === 7) &&
	    <a href="https://backend.delhimetrorail.com/documents/3124/Final_vendor_to_be_uploaded.pdf" target="_blank" download>
	      <p>Form of application for enlistment as Empanelled Manufacturer/Supplier for DMRC</p>
	    </a>
	  */}
	  {state === states.SUCCESS && (currentTab === 4) &&
	    <a href="https://backend.delhimetrorail.com/documents/3791/1678425491.pdf" target="_blank" download>
	      <p>Standardization/ Indigenization of Electrical  & Electromechanical Metro Rail Components.</p>
	    </a>
	  }
	  {state === states.SUCCESS && (currentTab === 4 || currentTab === 7 || currentTab === 9) &&
	    <a href="https://backend.delhimetrorail.com/documents/2667/DMRCs_List_of_Empanelled_Makes_for_Major_Electrical_Items_2022_1_7_22.pdf" target="_blank" download>
	      <p>List of Empanelled Makes for Major Electrical Items 2022.</p>
	    </a>
	  }
	  {/*state === states.SUCCESS && (currentTab === 2) &&
	    <a href="https://www.delhimetrorail.com/pages/en/shops-available-on-walk-in-basis" target="_blank">
	      <p>List of shops available on Walk in Basis</p>
	    </a>
	  */}
	  {state === states.SUCCESS && (currentTab === 2) &&
	    <a href="https://www.delhimetrorail.com/pages/en/licensing-of-built-up-openbare-space-and-parking-s" target="_blank">
	      <p style={{marginTop: '5px'}}><img src={NewImg} /> Licensing of Built-up, Open/Bare Space, and Parking space in Badli Depot of line-2 on Walk-in Basis</p>
	    </a>
	  }
	  {state === states.SUCCESS && (currentTab === 2) &&
	    <a href="https://www.delhimetrorail.com/pages/en/list-of-stations-available-on-walk-in-basis-for-co" target="_blank">
	      <p style={{marginTop: '5px'}}><img src={NewImg} /> List of Stations Available on Walk In Basis for Co - Branding Rights</p>
	    </a>
	  }
	  {state === states.SUCCESS && (currentTab === 2) &&
	    <a href="https://www.delhimetrorail.com/pages/en/shops-available-on-walk-in-basis" target="_blank">
	      <p style={{marginTop: '5px'}}>List of PB Shops Available on Walk in Basis</p>
	    </a>
	  }
	  {/*state === states.SUCCESS && (currentTab === 2) &&
	    <a href="https://www.delhimetrorail.com/pages/en/leasing-of-vacant-pd-spaces-on-walk-in-basis" target="_blank">
	      <p style={{marginTop: '5px'}}>Leasing of vacant PD spaces on walk-in -basis</p>
	    </a>
	  */}
	  <div id='tender' style={{display: 'block'}} className='modal advanced-search'>
        <div className='modal-dialog careerpopup tenderpopup modal-dialog-centered'>
			<div className='modal-content'>
			  <div className='circle-close-btn'>
				<button
				  id="buttonDismiss1"
				  className='btn-close'
				  style={{top: 15, right: 12}}
				  data-bs-dismiss='modal'
				  aria-label='Close'
				  onClick={handleClick}
				/>
			  </div>
			  <div className='modal-body p-0'>
				<img src={TenderImage} />
			  </div>
			</div>
		</div>
	  </div>
    </>
  )
}

const Tender = ({ data }) => {
  const { pathname } = useLocation()
  const { replace } = useHistory()
  const [currentTab, setCurrentTab] = useState(0)
  const { t } = useTranslation('tender')
  const lang = useContext(LocaleContext)
  const [newdata, setData] = useState(data)
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const handlePageChange = async (event,value) => {
	event.preventDefault()
    //setData(null)
    if (value) {
      const headers = new window.Headers()
      headers.set('Content-Type', 'application/json')
      const fetchOpts = { headers }
      try {
		let catId = data?.next?.split('tenders_by_category/')[1]?.split('/?')[0]
		setLoader(true)
        const res = await window.fetch(
          `${API_ENDPOINT}/${lang}/tenders_by_category/${catId}/?page=${value}`,
          fetchOpts
        )
        const json = await res.json()
        setData(json)
        setCurrentPage(value)
		setLoader(false)
      } catch (error) {
        console.log({ error })
		setLoader(false)
      }
    }
  }

  useEffect(() => {
    const hash = pathname
      .split('/')
      .filter((d) => d)
      .reverse()[0]
    const num = reverseMerryGoRound(hash)
    setCurrentTab(num)
  }, [pathname])

  const allTenders = fixTenderData(currentPage && currentPage > 1 ? newdata?.results : data?.results)
  const handleTabClick = (id) => {
    setCurrentTab(id)
    replace(
      pathname.replace(`${merryGoRound(currentTab)}`, `${merryGoRound(id)}`)
    )
  }
  return (
    <>
      <div className='container pb-3 mt-sm-4'>
        <TenderTab currentTab={currentTab} handleTabClick={handleTabClick} />
        <div className='table-responsive'>
          <table className='table table-bordered tenderstable'>
            <thead>
              <tr>
                <th>{t('head.title')}</th>
                <th>{t('head.data_of_sale')}</th>
                <th>{t('head.submission_date_time')}</th>
                <th>{t('head.opening_date_time')}</th>
                <th>{t('head.inviting_authority')}</th>
              </tr>
            </thead>
            <tbody>
              {allTenders.map((d, i) => (
                <tr key={i}>
                  <td>
                    <span className='fontweight600 d-none'>
                      <img src={NewImg} /> NOTICE INVITING TENDER
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: d.content }} />
                  </td>
                  <td>{d?.sale_date}</td>
                  <td>{d?.last_submission_date}</td>
                  <td>{d?.opening_date}</td>
                  <td>{d?.inviting_authority}</td>
                </tr>
              ))}
            </tbody>
          </table>
		  <div className="pagination">
			<Pagination
			  className="my-3"
			  count={Math.ceil(data?.count/10)}
			  page={currentPage}
			  variant="outlined"
			  shape="rounded"
			  onChange={handlePageChange}
			/>
		  </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Tender
