import { LocaleContext } from 'App'
import Call from 'assets/call.png'
import Call2 from 'assets/call2.png'
import Logo from 'assets/logo-passenger.svg'
import LogoHindi from 'assets/logo-passenger-hindi.svg'
import GtwentyImage from 'assets/images/header/g20.jpg'
import clsx from 'clsx'
import data from 'data/home'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useSimpleFetch } from 'helpers/pageUtils'

const Header = ({ serviceClick }) => {
  const [isNavOpen, setNavOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('');
  const lang = useContext(LocaleContext)
  const { t } = useTranslation('common')
  const history = useHistory();
  const [serviceStatus, serviceState] = useSimpleFetch(lang, 'service_information')

  const searching = (e,search_txt) => {
    e.persist();
    e.preventDefault();
    history.push({pathname:'/search/'+searchInput})
  }
  useEffect(() => {
	 //console.log(serviceStatus) 
	 //console.log(serviceState) 
  },[serviceStatus, serviceState])

  return (
    <header className='header'>
      <div className='container'>
        <div className='row'>
          <nav className='navbar'>
            <div className='navigation'>
              <button
                type='button'
                className='navbar-toggle collapsed'
                data-toggle='collapse'
                data-target='#mobile_nav'
                aria-expanded='false'
                onClick={() => setNavOpen(!isNavOpen)}
              >
                <svg
                  version='1.1'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 60.123 60.123'
                >
                  <path d='M57.124,51.893H16.92c-1.657,0-3-1.343-3-3s1.343-3,3-3h40.203c1.657,0,3,1.343,3,3S58.781,51.893,57.124,51.893z' />
                  <path d='M57.124,33.062H16.92c-1.657,0-3-1.343-3-3s1.343-3,3-3h40.203c1.657,0,3,1.343,3,3C60.124,31.719,58.781,33.062,57.124,33.062z' />
                  <path d='M57.124,14.231H16.92c-1.657,0-3-1.343-3-3s1.343-3,3-3h40.203c1.657,0,3,1.343,3,3S58.781,14.231,57.124,14.231z' />
                </svg>
              </button>
			  <div className='main-logo'>
				  <Link to='/' >
					 <img src={lang === 'en' ? Logo : LogoHindi} alt='delhimetrorail'  width="161" height="64"/>
				  </Link>
				  {/*<img src={GtwentyImage} alt='G-20' width="120" height="60" />*/}
			  </div>
              <div
                className={clsx({
                  'collapse navbar-collapse lateral-left': true,
                  open: isNavOpen,
                })}
                id='mobile_nav'
              >
                <div className='search'>
                  <form className='d-flex mb-3'>
                    <input
                      className='form-control'
                      type='search'
                      placeholder={t('header-links.search-placeholder')}
                      aria-label='Search'
                      value={searchInput}
                      onChange={e => setSearchInput(e.target.value)}
                    />
                    <button aria-label='Search' className='btn btn-outline-success' type='submit' disabled={!searchInput} onClick={(e) => searching(e,searchInput)}>
                      <i className='flaticon-loupe-1' />
                    </button>
                  </form>
                </div>
                <div className='header-links'>
                  <ul>
				  <li>
                      <a href='/'>
                        {t('header-links.home')}
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.dmrcsmartcard.com/' target="_blank"
                        
                      >
                        {t('header-links.recharge-smart-card')}
                      </a>
                    </li>
					
					<li>
                      <Link to='/facilities' onClick={() => setNavOpen(false)}>
                        {t('header-links.facilities')}
                      </Link>
                    </li>
					<li>
                      <Link to='/network' onClick={() => setNavOpen(false)}>
                        {t('header-links.network')}
                      </Link>
                    </li>
                    
					<li>
                      <Link to='/security-dmrc' onClick={() => setNavOpen(false)}>
                      {t('header-links.security-dmrc')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/contact-us'
                        onClick={() => setNavOpen(false)}
                      >
                        {t('header-links.helpcontact')}
                      </Link>
                    </li>
					<li>
					  <a
                        href='http://vcp.delhimetrorail.com/' target="_blank"
                        
                      >
                        {t('header-links.vigilance-complaint')}
                      </a>
                    </li>
					<li>
                      <Link to='/faq' onClick={() => setNavOpen(false)}>
                        {t('header-links.faq')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={clsx({
                  'menu-bg': true,
                  hidden: !isNavOpen,
                })}
                onClick={() => setNavOpen(!isNavOpen)}
              />
              <div className='main-nav'>
                <div className='search'>
				
                  <div className='me-3'>
                    <img src={lang === 'en' ? Call : Call2} alt="call" width="105" height="48"/>
                  </div>
                  <form className='d-flex mb-3'>
                    <input
                      className='form-control'
                      type='search'
                      placeholder={t('header-links.search-placeholder')}
                      aria-label='Search'
                      value={searchInput}
                      onChange={e => setSearchInput(e.target.value)}
                    />
                    <button aria-label='Search' className='btn btn-outline-success' type='submit' disabled={!searchInput} onClick={(e) => searching(e,searchInput)}>
                      <i className='flaticon-loupe-1' />
                    </button>
                  </form>
                </div>

                <div className='header-links'>
                  <ul>
				  <li>
                      <a href='/'>
                        {t('header-links.home')}
                      </a>
                    </li>
                    <li>
                      <a href='https://www.dmrcsmartcard.com/' target="_blank">
                        {t('header-links.recharge-smart-card')}
                      </a>
                    </li>
					
					<li>
                      <Link to='/facilities'>
                        {t('header-links.facilities')}
                      </Link>
                    </li>
					<li>
                      <Link to='/network'>
                        {t('header-links.network')}
                      </Link>
                    </li>
                    
					<li>
                      <Link to='/security-dmrc'>
                        {t('header-links.security-dmrc')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/contact-us'>
                        {t('header-links.helpcontact')}
                      </Link>
                    </li>
					<li>
					  <a
                        href='http://vcp.delhimetrorail.com/' target="_blank"
                        
                      >
                        {t('header-links.vigilance-complaint')}
                      </a>
                    </li>
					<li>
                      <Link to='/faq'>{t('header-links.faq')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
				<div className="buttonclickhere">
                  <a href='#service' onClick={serviceClick}>
                  {t('service.click-service-information')}
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
