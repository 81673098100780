import { LocaleContext } from 'App'
import clsx from 'clsx'
import { states, useLineUpdates, useSimpleFetch } from 'helpers/pageUtils'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import State from './State'

const ServiceStatus = ({ customClass, showSatusHeading }) => {
  const history = useHistory()
  const lang = useContext(LocaleContext)
  const [data, state] = useSimpleFetch(lang, 'line_list')
  
  const updates = useLineUpdates()
  const { t } = useTranslation('common')
  const statusMap = {
    'Partial Service': 'planned',
    'आंशिक सेवा': 'planned',
    'Normal Service': 'normal',
    'नार्मल सर्विस': 'normal',
    'सामान्य सेवाएँ': 'normal',
    'No Service': 'no-service',
    'सेवा निषेद': 'no-service',
	'Delay in Service': 'delay',
    'सेवा में देरी': 'delay',
  }

  useEffect(() => {
    if (updates?.payload?.data && Array.isArray(data) && data.length) {
      const index = parseInt(updates?.payload?.data?.id || -1)
      const status = updates?.payload?.data?.status
      if (index !== -1 && data[index]) {
        data?.forEach((d, i) => {
          if (i === index) {
            d.status = status ? 'Normal Service' : 'No Service'
          }
        })
      }
    }
  }, [updates, data])

  return (
    <section id='service' style={customClass} className='service-status'>
      <div className='container'>
        <div className='row'>
          <div className='post-heading'>
            {showSatusHeading && (
              <h2 className='line'>{t('service.service-status')}</h2>
            )}
          </div>
          <div className=''>
            {state === states.LOADING && <State.Loading height='compact' />}
            {state === states.ERROR && (
              <State.Errored
                height='compact'
                message='Failed to load line status'
              />
            )}
            {state === states.SUCCESS && (
              <ul>
                {Array.isArray(data) &&
                  data.length &&
                  data.map(({ name, line_color, line_code, status,show_in_frontend }, i) => (
                    <li style={show_in_frontend?{display:"list-item"}: {display: "none"}}
                      onClick={() => history.push(`/line/${line_code}`)}
                      className={clsx([
                        line_code.toLowerCase(),
                        statusMap[status],
                      ])}
                      key={i}
                    >
                      <figure className='shodow-lg'>
                      <i className='flaticon-train'></i>
                      <h4>
                        <span className="">{name}</span>
                        <br />
                        <span className="text-uppercase">{line_color}</span>
                      </h4>
                    </figure> 
                      <p className='status'>{status}</p>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceStatus
