import { useTranslation } from 'react-i18next'
import Logoatech from '../../assets/arohatech-logo.png'
import Niclogo from '../../assets/NIC-LOGO.png'
const Copyright = () => {
  const { t } = useTranslation('common')
  return (
    <footer className='copyright' style={{ marginTop: 'unset' }}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md-4'>
            <p>
              {t('footer.copy-right1')} {new Date().getFullYear()} {t('footer.copy-right2')}
            </p>
          </div>
		  
          <div className='col-md-4'>
		  <ul className='inline justify-content-center'>
		  <li>
               <a href="/web-information-manager"><p>{t('corporate-footer.web-info')}</p></a> 
              </li>
              </ul>
			  </div>
			    <div className='col-md-4'>
				 <ul className='inline d-flex  align-items-center'>
				<li><a href="https://www.arohatech.com/" target="_blank" title={t('footer.arohatech-services')}><img src={Logoatech} alt="Arohatech - Web Portal Development Company and Mobile App Developer for Delhi Metro Rail Corporation (DMRC) India" style={{width: '100px', height: '33px'}} /> </a></li>
				<li><a href="https://www.nic.in/" target="_blank" title={t('footer.hosted-nic')}><img src={Niclogo} alt="National Informatics Center" width="80" height="18"/> </a></li>
				</ul>
            <ul className='d-none'>
              <li className="">
                <a href='/privacy-policy' alt='Terms of use'>
                  {t('footer.terms-of-use')}
				  {t('footer.privacy-policy')}
                </a>
              </li>
              <li>
                <a href='/privacy-policy' alt='Privacy Policy'>
                  
				  {t('footer.terms-of-use')} | {t('footer.privacy-policy')}
                </a>
              </li>
			  
            </ul>
          </div>
        </div>
		</div>
    </footer>
  )
}
export default Copyright
