import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/tooltip'
import { ensurePassenger } from 'helpers/pageUtils'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import 'popper.js'
import { createContext, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './App.scss'
import './i18n'
import NetworkMap1 from 'components/passenger/map/NetworkMap1'
import ReactGA from 'react-ga';
import React, { Suspense, lazy } from 'react';
import loadingImg from 'assets/dmrc.gif'
import packageJson from '../package.json';

const TopHeader = lazy(() => import('components/common/TopHeader'));
// corporate pages
const CorporateHome = lazy(() => import('pages/corporate/Home'))
const Pages = lazy(() => import('pages/Pages'))
const Details = lazy(() => import('pages/passenger/Detail'))
// passenger pages
const PassengerHome = lazy(() => import('pages/passenger/Home'))
const Line = lazy(() => import('pages/passenger/Line'))
const Map = lazy(() => import('pages/passenger/Map'))
const NotFound = lazy(() => import('pages/passenger/NotFound'))
const Station = lazy(() => import('pages/passenger/Station'))
const Tour = lazy(() => import('pages/passenger/Tour'))
const LostFoundPage = lazy(() => import('pages/passenger/LostFoundPage'))
const ViewAll = lazy(() => import('components/passenger/home/ViewAll'))
const Search = lazy(() => import('pages/passenger/Search'))
const TravelAdvisory = lazy(() => import('pages/passenger/TravelAdvisory'))
const Kiosk = lazy(() => import('components/common/Kiosk'))
const KioskPassenger = lazy(() => import('components/common/KioskPassenger'))
const KioskMuseum = lazy(() => import('components/common/KioskMuseum'))

export const LocaleContext = createContext()
export const UpdateContext = createContext()

const App = () => {
  ReactGA.initialize('UA-102628-11');
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { i18n } = useTranslation()
  const { pathname: currentPath } = useLocation()
  const isPassenger = ensurePassenger(currentPath)
  const [lang, setLang] = useState(window.localStorage?.getItem('lang') || 'en')
  const [redirectPath, setRedirectPath] = useState([])
  const pageRef = useRef()
  const availHeight = window.screen.availHeight;
  
  useEffect(()=>{
    let version = localStorage.getItem('version');
	console.log(version);
	if(version!=packageJson.version) {
	  if('caches' in window) {
	    caches.keys().then((names) => {
		  // Delete all the cache files
		  names.forEach(name => {
		    caches.delete(name);
		  })
		});
		// Makes sure the page reloads. Changes are only visible after you refresh.
		window.location.reload(true);
	  }
	  localStorage.clear();
	  localStorage.setItem('version',packageJson.version);
	}
  },[])

  const updateLang = (l) => {
    const current = currentPath.split('/')
    if (current[1] === 'pages' && current[2] !== l) {
      pageRef.current.onLangChange(current[2], l)
    }
    setLang(l)
    if (i18n.language !== l) {
      i18n.changeLanguage(l)
    }
    window.localStorage.setItem('lang', l)
  }

  const current = currentPath.split('/')
  if (current[1] === 'pages' && current[2] !== lang) {
    updateLang(current[2])
  }

  return (
    <div className={isPassenger ? 'passenger' : 'corporate'}>
	  <Suspense fallback={<div style={{textAlign: 'center', margin: 'auto'}}><img style={{width: '400px', marginTop: '10%'}} src={loadingImg} /></div>}>
      <LocaleContext.Provider value={lang}>
       {availHeight <= 3000 ? <TopHeader lang={lang} updateLang={updateLang} /> : ''}
        <Switch>
          {redirectPath.length && (
            <Redirect from={redirectPath[0]} to={redirectPath[1]} />
          )}
          <Route path='/' exact>
            <PassengerHome />
          </Route>
          <Route path='/map' exact>
            <Map />
          </Route>
          <Route path='/line/:name' exact>
            <Line />
          </Route>
          <Route path='/station/:id' exact>
            <Station />
          </Route>
          <Route path='/tour-guide' exact>
            <Tour />
          </Route>
          <Route path='/view-all' exact>
            <ViewAll />
          </Route>
		  <Route path='/kiosk' exact>
            <Kiosk />
          </Route>
		  <Route path='/passenger-kiosk' exact>
			<KioskPassenger />
		  </Route>
		  <Route path='/museum-kiosk' exact>
			<KioskMuseum />
		  </Route>
          <Route path='/network_map' exact>
            <NetworkMap1 />
          </Route>
          <Route path='/lost-found-list' exact>
            <LostFoundPage />
          </Route>
		  <Route path='/travel-advisory' exact>
            <TravelAdvisory />
          </Route>
          <Route path='/search/:id' exact>
            <Search />
          </Route>
          <Route path='/corporate' exact>
            <CorporateHome />
          </Route>
          <Route path='/pages/:lang/:slug*'>
            <Pages ref={pageRef} onRedirect={setRedirectPath} />
          </Route>
          {isPassenger && (
            <Route path='/:name' exact>
              <Details />
            </Route>
          )}
          <Route component={NotFound} />
        </Switch>
      </LocaleContext.Provider>
      </Suspense>
    </div>
  )
}

export default App
