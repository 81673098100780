import { useTranslation } from 'react-i18next'
import State from 'components/common/State'
import { LocaleContext } from 'App'
import Copyright from '../../common/Copyright'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import { API_BASE } from 'helpers/pageUtils'
import React, { useContext,Fragment, useState } from 'react'
import MapImg from '../../../assets/DMRC-Network-Map-27 May-2024-Bilingual.jpg'
import MapImg1 from '../../../assets/DMRC-Network-Map-27 May-2024-Bilingual.jpg'
import MapPdf from '../../../assets/Network-Map-24-May-2024-Bilingual.pdf'
import MapPdf1 from '../../../assets/Network-Map-24-May-2024-Bilingual.pdf'
import { Link, useLocation } from 'react-router-dom'
import ServiceStatus from 'components/common/ServiceStatus'
import moment from 'moment'
import Magnifier from "react-magnifier";

const NetworkMap1 = () => {
	const [serviceVisible, setServiceVisible] = useState(false)
  const { t } = useTranslation('passenger')   
  const lang = useContext(LocaleContext)
  return (
      <div>
       <Header  serviceClick={(e) => {
          e.preventDefault()
          setServiceVisible(!serviceVisible)
        }}/>
	   {serviceVisible && <ServiceStatus />}
		<div className="corporate container pb-3 mt-4">
        <h1>{t('plan-journey.network-map')} </h1>
		<div className="my-4">
        <Magnifier src={lang === 'en' ? MapImg1 : MapImg}  mgShape={'square'}/>
		</div>
		<div className="text-center mb-4"><a className="moreviewtikct" download="DMRC_MetroMap2021" href={lang === 'en' ? MapPdf1 : MapPdf} target="_blank" title="download map">{t('map.pdf-download')}</a></div>
		</div>
        <Footer />
        <Copyright />
     
     </div>
    
  )
}

export default NetworkMap1
